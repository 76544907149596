<template>
	<div class="show-popup">
		<div class="popup-mask" @click="closePopup">
			<div class="customerService">
				<div class="customerService-box">
					<div class="customerService-top">
						<img style="margin-left: 0.30rem;" class="customerService-top-icon" src="../assets/img/customerService-icon.png" >
						<div style="flex: 1;text-align: left;margin-left: 0.20rem;">
							<div class="customerService-top-tit">您好，我是您的专属顾问</div>
							<div class="customerService-top-lab">扫码添加我的微信，获取更多产品资料</div>
						</div>
					</div>
					<div class="customerService-bot">
						<img src="../assets/img/customerService-code.png" >
						<div class="customerService-bot-text">力瀚专业客户服务</div>
					</div>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data () {
			return  {}
		},
		methods:{
			closePopup(){
				// console.log('关闭客服')
				this.$emit('close-popup')
			}
		}
	}
</script>

<style lang="scss" scoped>
	
	.show-popup{
		position:fixed;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index:1004;
		.popup-mask{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background-color:rgba(0,0,0,0.65);
			z-index:1005;
			.customerService{
				width: 100%;
				height: 100%;
				position: relative;
				.customerService-box{
					width: 6.00rem;
					position: fixed;
					left: 0;
					right: 0;
					margin: 0 auto;
					top: 50%;
					margin-top: -3.00rem;
					
					.customerService-top{
						width: 100%;
						display: flex;
						align-items: center;
						
						// justify-content: center;
						padding: 0.20rem 0;
						background: linear-gradient(90deg, #2E89F9, #06D8EE);
						.customerService-top-icon{
							width: 0.8rem;
						}
						.customerService-top-tit{
							font-size: 0.30rem;
							color: #fff;
							font-weight: 500;
							
						}
						.customerService-top-lab{
							font-size: 0.18rem;
							color: #fff;
							font-weight: 400;
							
						}
					}
					
					.customerService-bot{
						width: 100%;
						background-color: #fff;
						padding: 0.60rem 0;
						.customerService-bot-text{
							font-size: 0.20rem;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #7C7C7C;
							margin-top: 0.20rem;
						}
					}
				}
				
			}
		}
	}
	
</style>
