import request from "@/utils/request";

export function setFrom(data) {
  return request({
    url: '/xx/common/demand/add',
    method: 'post',
    params: data
  })
}

export function GetCode(data) {
  return request({
    url: '/xx/common/code',
    method: 'post',
    params: data
  })
}