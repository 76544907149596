import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(Router)


const Layout = () => import('../layout');

const routes = [{
	path: '/',
	name: 'Layout',
	component: Layout,
	redirect: 'index',
	children: [{
			path: 'index',
			component: () => import('../views/Home.vue'),
			meta: {
				title: '首页'
			}
		},
		{ // 关于我们
			path: '/about',
			name: 'About',
			component: () => import('../views/About.vue'),
			meta: {
				title: '关于我们'
			}
		},
		{ // 客户案例
			path: '/case',
			name: 'Case',
			component: () => import('../views/case.vue'),
			meta: {
				title: '客户案例'
			}
		},
		{ // 新闻资讯
			path: '/Journalism',
			name: 'Journalism',
			component: () => import('../views/Journalism.vue'),
			meta: {
				title: '新闻资讯'
			}
		},
		{ // 企业福利
			path: '/welfare',
			name: 'Welfare',
			component: () => import('../views/welfare.vue'),
			meta: {
				title: '企业福利'
			}
		},
		{ // 电商
			path: '/onlineRetailers',
			name: 'OnlineRetailers',
			component: () => import('../views/onlineRetailers.vue'),
			meta: {
				title: '电商'
			}
		},
		{ // 新零售
			path: '/newRetail',
			name: 'NewRetail',
			component: () => import('../views/newRetail.vue'),
			meta: {
				title: '新零售'
			}
		},
		
		{ // 业务中台
			path: '/middleground',
			name: 'Middleground',
			component: () => import('../views/middleground.vue'),
			meta: {
				title: '业务中台'
			}
		},
		
		{ // 微商城
			path: '/microMall',
			name: 'MicroMall',
			component: () => import('../views/microMall.vue'),
			meta: {
				title: '微商城'
			}
		},
		
		{ // 小程序
			path: '/applet',
			name: 'Applet',
			component: () => import('../views/applet.vue'),
			meta: {
				title: '小程序'
			}
		},
		{ // 数据中台
			path: '/dataMiddleground',
			name: 'DataMiddleground',
			component: () => import('../views/dataMiddleground.vue'),
			meta: {
				title: '数据中台'
			}
		},
		{ // 供应链系统
			path: '/supplyChain',
			name: 'SupplyChain',
			component: () => import('../views/supplyChain.vue'),
			meta: {
				title: '供应链系统'
			}
		},
		{ // PC商城
			path: '/PCShoppingMall',
			name: 'PCShoppingMall',
			component: () => import('../views/PCShoppingMall.vue'),
			meta: {
				title: 'PC商城'
			}
		},
	]
}]


const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})


export default router
