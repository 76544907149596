<template>
	<div class="applyFrom">
		<div class="applyFrom-top">
			<span class="applyFrom-top-tit">注册申请</span><br />
			<span class="applyFrom-top-lab">请完善以下信息，让我们了解您的需求，以便第一时间联系您</span>
			<i class="el-icon-close" @click="close"></i>
		</div>

		<div class="applyFrom-bot">
			<el-form ref="ruleForm" :rules="rules" :model="form" label-width="100px"
				style="width: 80%;margin-right: 0.80rem;">
				<el-form-item label="* 姓名" prop="name">
					<el-input v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item label="* 公司" prop="company">
					<el-input v-model="form.company"></el-input>
				</el-form-item>
				<el-form-item label="* 手机" prop="phone">
					<el-input v-model="form.phone"></el-input>
				</el-form-item>
				<el-form-item label="* 验证码" prop="code">
					<el-input v-model="form.code">
						<el-button v-show="show" slot="append" type="primary" @click="getCode">获取验证码</el-button>
						<!-- <el-button v-show="!show" slot="append" type="primary">{{count}} 秒后重试</el-button> -->
						<span v-show="!show" slot="append">{{count}} 秒后重试</span>
					</el-input>
				</el-form-item>
				<el-form-item label="职位">
					<el-input v-model="form.job_position"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="form.email"></el-input>
				</el-form-item>
				<el-form-item label="留言">
					<el-input type="textarea" v-model="form.content"></el-input>
				</el-form-item>
				<el-form-item label="">
					<el-button class="form-item-but" style="background: #fff;color: #28A1F5;width: 3.00rem;font-size: 0.20rem;" type="primary"
						@click="onSubmit('ruleForm')">提交申请</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		GetCode,
		setFrom
	} from '@/api/applyFrom';
	export default {
		data() {
			var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
			var ValidatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请填写手机号'));
				} else if (!reg_tel.test(value)) {
					callback(new Error('请填写正确手机号!'));
				} else {
					callback();
				}
			};

			var ValidateCode = (rule, value, callback) => {
				if (this.form.phone === '') {
					callback(new Error('请填写手机号'));
				} else if (value === '') {
					callback(new Error('请填写验证码'));
				} else {
					callback();
				}
			};
			return {
				show: true,
				count: '',
				timer: null,
				form: {
					name: '',
					company: '',
					phone: '',
					code: '',
					job_position: '',
					email: '',
					content: ''
				},
				rules: {
					phone: [{
						validator: ValidatePhone,
						trigger: 'change'
					}],
					name: [{
						required: true,
						message: '请填写姓名',
						trigger: 'change'
					}],
					company: [{
						required: true,
						message: '请填写公司',
						trigger: 'change'
					}],
					code: [{
						validator: ValidateCode,
						trigger: 'change'
					}],
				}

			}
		},

		methods: {
			onSubmit(formName) {
				let that = this;
				this.$refs[formName].validate((valid) => {
					if (valid) {
						setFrom(that.form).then(res => {
							that.$message({
								message: '提交成功',
								type: 'success'
							});

							that.close();

						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			close() {
				this.$emit('close', {})
			},

			// 获取验证码
			getCode() {
				// console.log("-------------- getCode  start ---------------")
				var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
				if (this.form.phone === '' || !reg_tel.test(this.form.phone)) {
					this.$message({
						message: '请输入正确手机号',
						type: 'error'
					});
				} else {
					const TIME_COUNT = 60;
					if (!this.timer) {
						this.count = TIME_COUNT;
						this.show = false;
						this.timer = setInterval(() => {
							if (this.count > 0 && this.count <= TIME_COUNT) {
								this.count--;
							} else {
								this.show = true;
								clearInterval(this.timer);
								this.timer = null;
							}
						}, 1000)

						let data = {
							ty: 'leave_msg',
							phone: this.form.phone
						}
						// 获取验证码
						GetCode(data).then(res => {})
					}
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 750px) {
		// .applyFrom {
		// 	width: 80% !important;
		// 	margin-top: -5.0rem !important;
		// }
		.el-form-item {
			margin-bottom: 0 !important;
		}
		.form-item-but{
			margin: 0.3rem 0;
		}
		
		.applyFrom{
			margin-top: -5.0rem !important;
		}
		
		.el-input-group__append{
			height: 2rem !important;
		}
		

	}

	.applyFrom {
		position: fixed;
		width: 5rem;
		// height: 600px;
		margin: 0 auto;
		z-index: 105;
		left: 0;
		right: 0;
		top: 50%;
		margin-top: -3.0rem;
		background-color: #fff;
		border-radius: 0.30rem;
		overflow: hidden;
		box-shadow: -1px -4px 25px -2px #ccc;

		// background: linear-gradient(90deg, #2E89F9, #73B2FF);
		.applyFrom-top {
			width: 100%;
			line-height: 0.20rem;
			padding: 0.20rem 0;
			position: relative;

			.el-icon-close {
				position: absolute;
				font-size: 0.20rem;
				right: 0.20rem;
				top: 0.10rem;
			}

			.applyFrom-top-tit {
				font-size: 0.30rem;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #333333;
			}

			.applyFrom-top-lab {
				font-size: 0.15rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.applyFrom-bot {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: linear-gradient(90deg, #2E89F9, #73B2FF);
			// padding: 0 20px;
			// padding: 20px 0;
			padding-top: 0.40rem;
		}
	}
</style>

<style type="text/css">
	.el-form-item__label {
		color: #fff !important;
		font-size: 0.15rem !important;
	}

	.el-form-item__label:before {
		color: transparent !important;
	}

	.el-input__inner {
		height: 0.3rem !important;
		-webkit-appearance: none;
		background-color: #FFF;
		background-image: none;
		border-radius: 0.04rem;
		border: 0.01rem solid #DCDFE6;
		box-sizing: border-box;
		color: #606266;
		display: inline-block;
		font-size: inherit;
		line-height: 0.30rem !important;
		outline: 0;
		padding: 0 0.15rem !important;
		transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
		width: 100%;
	}

	.el-input-group__append {
		width: 1.2rem !important;
		height: 0.28rem !important;
		font-size: 0.16rem !important;
		padding: 0 0.2rem !important;
	}

	.el-input-group__append .el-button {
		padding: 0.1rem 0.2rem !important;
	}

	.el-form-item {
		margin-bottom: 0.18rem !important;
	}

	.el-form-item__label {
		width: 1rem !important;
		padding: 0 0.12rem 0 0 !important;
	}

	.el-form-item__content {
		margin-left: 1rem !important;
	}
	
	.el-form-item__content .el-input-group {
	    vertical-align: middle !important;
	}
	
	.el-form-item__error{
		/* padding-top: 0.04rem !important; */
		/* line-height: 0.1rem !important; */
		
	}
</style>
