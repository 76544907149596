import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/dist/css/swiper.css';
import AmapVue from '@amap/amap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import '@/utils/rem.js'

Vue.config.productionTip = false

Vue.use( VueAxios , axios)
AmapVue.config.key = '335fec9b235c6f73b6c73f13f597e720'
Vue.use(AmapVue)


Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


router.afterEach((to,from,next) => {undefined

    window.scrollTo(0,0);

})

// const setHtmlFontSize = () => {
//   const htmlDom = document.getElementsByTagName('html')[0];
//   let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
//   if (htmlWidth >= 750) {
//     htmlWidth = 750;
//   }
//   if (htmlWidth <= 320) {
//     htmlWidth = 320;
//   }
//   htmlDom.style.fontSize = `${htmlWidth / 7.5}px`;
// };
// window.onresize = setHtmlFontSize;
// setHtmlFontSize();