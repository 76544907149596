<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	
	export default {
		name: 'App',
		created() {

		}
	}
</script>

<style>
	#app {
		font-family: 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		/* margin-top: 60px; */
	}

	* {
		margin: 0;
		padding: 0;
	}

	.flex {
		display: flex;

		align-items: center;
		justify-content: center;
	}

	a {
		text-decoration: none;
		color: #ccc;
	}
</style>
