<template>
  <div class="home">
	  <applyFrom @close="close" v-if="applyFromshow"></applyFrom>
	  <customerService @close-popup="closePopup" v-if="customerService"></customerService>
		<div class="Topbanner">
			<!-- <div style="position: fixed;top: 0;left:0;bottom: 0;right: 0;z-index: 1;">
				<canvas id="canvas" style="background-color: transparent;"></canvas>
			</div> -->
			<div class="Topbanner-body">
				<div class="TopB-body">
					<div class="TopB-body-title">数字化驱动转型 力瀚引领新时代</div>
					<div class="TopB-body-lab">助力品牌构建全渠道中台+数据营销落地的新零售解决方案</div>
					<div class="sqsy" @click="applyFromshow = true">申请试用</div>
				</div>
			</div>
			<img style="width: 100%;" src="../assets/img/bj-1.png">
		</div>
		
		<div class="Right-cell">
			<div class="Right-cell-text">
				<img class="Right-cell-img" src="../assets/img/wx.png">
				<span class="Right-cell-span" style="padding-right: 0.30rem;">微信二维码</span>
				<div class="Right-cell-text-box">
					<img class="Right-cell-text-img" src="../assets/img/qywx.png">
					<div style="padding:0 0.05rem;">加微信咨询产品详情</div>
				</div>
				
			</div>
				
		</div>
		<div class="Right-cell1">
			<img class="Right-cell-img" src="../assets/img/phone.png">
			<span class="Right-cell-span spandis">400-821-6000</span>
		</div>
		<div class="Right-cell2" style="margin-top: 1.00rem;">
			<span class="Right-cell-span">试用</span>
			<span class="Right-cell-span spandis" style="color: transparent;">400-821-6000</span>
			<!-- <span class="Right-cell-img">试用</span> -->
		</div>
		<div class="Left" @click="customerService = true">
			<span>在线咨询</span>
			<img class="Left-img" src="../assets/img/zx.png">
		</div>
		
		<div class="Topbanner2">
			<div class="Topbanner2-body">
				<div class="Topbanner2-body-title">企业数字化转型</div>
				<div class="Topbanner2-body-lab">双中台承接数字化转型核心，打造企业全链路交易闭环</div>
				<!-- <div class="Topbanner2-body-but">
					8年技术沉淀服务20万家客户
				</div> -->
			</div>
			<img style="margin-top: 1.80rem;width: 100%;" src="../assets/img/shuju.gif" >
			<!-- <img src="img/bj-2_01.jpg">
			<img src="img/bj-2_02.jpg"> -->
		</div>
		
		<div class="Topbanner3">
			<img style="width: 100%;height: 100%;" src="../assets/img/bj-3.png">
			<div class="Topbanner3-body">
				<div style="display: flex;justify-content: space-between;flex-wrap: wrap;">
					<router-link to="/middleground">
						<div class="Topbanner3-body-cell" >
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit">业务中台</span><br />
								<span class="cell-le-lab">全渠道业务，资源一体化融合</span>
							</div>
							<img class="Topbanner3-body-cell-img" src="../assets/img/iconZt.png">
						</div>
					</router-link>
					
					<router-link to="/dataMiddleground">
						<div class="Topbanner3-body-cell">
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit">数据中台</span><br />
								<span class="cell-le-lab">企业的数据智能</span>
							</div>
							<img class="Topbanner3-body-cell-img" src="../assets/img/sj-icon.png">
						</div>
					</router-link>
					
					<router-link to="">
						<div class="Topbanner3-body-cell">
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit">开放中台</span><br />
								<span class="cell-le-lab">满足企业不同场景的数据需求</span>
							</div>
							<img class="Topbanner3-body-cell-img" src="../assets/img/kfpt.png">
						</div>
					</router-link>
					<router-link to="/supplyChain" class="Topbanner3-bodyL" >
						<div class="Topbanner3-body-cell" >
							<div class="Topbanner3-body-cell-le Topbanner3-body-cell-leL" style="width: 100%;">
								<span class="cell-le-tit">供应链系统</span><br />
								<span class="cell-le-lab">S2B2C供应链平台，满足商家批发采购需求</span>
							</div>
						</div>
					</router-link>
					
					<router-link to="/applet" style="width: 24%;" class="Topbanner3-body-cellLe">
						<div class="Topbanner3-body-cell ">
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit" style="padding-bottom: 0.2rem;">小程序商城</span><br />
								
							</div>
						</div>
					</router-link>
					<router-link to="/microMall" style="width: 24%;" class="Topbanner3-body-cellLe">
						<div class="Topbanner3-body-cell">
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit" style="padding-bottom: 0.2rem;">微商城</span><br />
							</div>
						</div>
					</router-link>
					<router-link to="/PCShoppingMall" style="width: 24%;" class="Topbanner3-body-cellLe">
						<div class="Topbanner3-body-cell">
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit" style="padding-bottom: 0.2rem;">PC商城</span><br />
							</div>
						</div>
					</router-link>
				</div>
				
				<!-- <div style="display: flex;justify-content: space-around;width: 100%;flex-wrap: wrap;">
					<router-link to="/supplyChain" style="width: 24%;">
						<div class="Topbanner3-body-cell" >
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit">供应链系统</span><br />
								<span class="cell-le-lab">S2B2C供应链平台，满足商家批发采购需求</span>
							</div>
						</div>
					</router-link>
					
					<router-link to="/applet" style="width: 24%;">
						<div class="Topbanner3-body-cell">
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit">小程序商城</span><br />
							</div>
						</div>
					</router-link>
					<router-link to="/microMall" style="width: 24%;">
						<div class="Topbanner3-body-cell">
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit">微商城</span><br />
							</div>
						</div>
					</router-link>
					<router-link to="/PCShoppingMall" style="width: 24%;">
						<div class="Topbanner3-body-cell">
							<div class="Topbanner3-body-cell-le">
								<span class="cell-le-tit">PC商城</span><br />
							</div>
						</div>
					</router-link>
					
				</div> -->
			</div>
		</div>
		
		<div class="Topbanner4">
			<div class="Topbanner4-title">力瀚新零售数字化运营解决方案</div>
			<div class="Topbanner4-body">
				<div class="Topbanner4-body-l">
					<div class="Top4-body-l" @mouseover="mouseOver(1)" @mouseleave="mouseLeave(1)">
						<div class="hover1box">
							
							<transition name="fade">
							    <img v-if="!hover1" class="body-l-cell" src="../assets/img/xls-n.png">
							</transition>
							<transition name="fade">
							    <img v-if="hover1" class="body-l-cell-y" src="../assets/img/xls-y.png">
							</transition>
							
						</div>
					</div>
					<div class="Top4-body-l Top4-body-ltop1" id="2" @mouseover="mouseOver(2)" @mouseleave="mouseLeave(2)">
						
						<transition name="fade">
						    <img v-if="!hover2" class="body-l-cell" src="../assets/img/sjds-n.png">
						</transition>
						<transition name="fade">
						    <img v-if="hover2" class="body-l-cell-y" src="../assets/img/sjds-y.png">
						</transition>
						
					</div>
					<div class="Top4-body-l Top4-body-ltop2" id="3" @mouseover="mouseOver(3)" @mouseleave="mouseLeave(3)">
						<transition name="fade">
						    <img v-if="!hover3" class="body-l-cell" src="../assets/img/gyl-n.png">
						</transition>
						
						<transition name="fade">
						    <img v-if="hover3" class="body-l-cell-y" src="../assets/img/gyl-y.png">
						</transition>
						
					</div>
					<div class="Top4-body-l Top4-body-ltop3 hover4" id="4" @mouseover="mouseOver(4)" @mouseleave="mouseLeave(4)">
						
						<transition name="fade">
						    <img v-if="!hover4" class="body-l-cell" src="../assets/img/qyfl-n.png">
						</transition>
						<transition name="fade">
						    <img v-if="hover4" class="body-l-cell-y" src="../assets/img/qyfl-y.png">
						</transition>
						
					</div>
					<!-- <div class="Top4-body-l" id="1">
						<img class="body-l-cell" src="img/sjds-n.png" style="top: 110px;">
						<img class="body-l-cell-y" src="img/sjds-y.png" style="top: 110px;">
					</div> -->
					<!-- <img class="body-l-cell" src="img/gyl-n.png" style="top: 220px;">
					<img class="body-l-cell" src="img/qyfl-n.png" style="top: 330px;"> -->
				</div>
				<div class="Topbanner4-body-r">
					<img style="width: 100%;height: 100%;" :src="imgs">
				</div>
			</div>
		</div>
		<div class="Topbanner5">
			<div class="Topbanner5-title">我们的合作伙伴远不止这些</div>
			<div class="Topbanner5-body">
				<img style="width: 100%;" src="../assets/img/brand.png">
			</div>
		</div>
		<div class="Topbanner6 flex">
			<div class="Topbanner6-body">
				<div class="Topbanner6-body-phone">
					<img class="Top6-phone" src="../assets/img/phone-y.png">
					<span class="Top6-phone-span">联系电话：400-821-6000</span>
				</div>
				<div class="Topbanner6-body-input">
					<div class="Top6-input">想获取企业数字化解决方案？</div>
					<div class="Top6-but"  @click="applyFromshow = true">点击申请</div>
				</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/bj-4.png">
		</div>
  </div>
</template>


<script>
	// import Canvas from '@/common/index.js'
	import applyFrom from '@/components/applyFrom.vue';
	import customerService from '@/components/customerService.vue';
export default {
	data () {
		return {
			imgs: '',
			hover1: true,
			hover2: false,
			hover3: false,
			hover4: false,
			applyFromshow: false,
			customerService: false
		}
	},
	components: {
	    // Canvas，
		applyFrom,
		customerService
	  },
	created() {
		this.judge();
		// this.zhizhuwang();
	},
	methods:{
		
		close(){
			this.applyFromshow = false;
		},
		
		closePopup(){
			this.customerService = false
		},
		
		mouseOver(index){
			this.hover1 = false; 
			this.hover2 = false;
			this.hover3 = false;
			this.hover4 = false;
			switch (index){
				case 1:
				  this.hover1 = true;
				break;
				case 2:
				  this.hover2 = true;
				break;
				case 3:
				  this.hover3 = true;
				break;
				case 4:
				  this.hover4 = true;
				break;
			}
			
			this.judge();
			// this.hover + index = true;
			// this.('hover' + index) = true;
			// console.log('移入',index)
		},
		mouseLeave(index){
			// console.log('移除',index)
		},
		judge(){
			if(this.hover1){
				this.imgs = require('../assets/img/Topbanner_1.png');
			}else if(this.hover2){
				this.imgs = require('../assets/img/Topbanner_2.png');
			}else if(this.hover3){
				this.imgs = require('../assets/img/Topbanner_3.png');
			}else if(this.hover4){
				this.imgs = require('../assets/img/Topbanner_4.png');
			}
		},
	}
}
</script>
<style type="text/css" scoped>
	/* img{
		width: 100%;
	} */
	
	@media screen and (max-width: 750px) {
		.Topbanner4-body{
			width: 90% !important;
		}
		
		.Topbanner3-body-cell-leL{
			width: 3.2rem !important;
		}
		
		.Topbanner3-body-cellLe{
			width: 32% !important;
		}
		
		.Topbanner4-body-l{
			height: 3.0rem !important;
		}
		
		.Topbanner4-body-r{
			width: 4.7rem !important;
			height: 3.0rem !important;
		}
		
		.Topbanner3-bodyL{
			width: 3.3rem;
		}
		.Topbanner6{
			height: 2rem ;
			
		}
		
		.Topbanner5-body{
			width: 90% !important;
		}
		
		.body-l-cell{
			width: 2rem !important;
			height: 0.6rem !important;
		}
		
		.body-l-cell-y{
			width: 2.3rem !important;
			height: 0.6rem !important;
		}
		
		.TopB-body{
			width: 90% !important;
		}
		
		.Top4-body-ltop1{
			top: 0.8rem !important;
		}
		
		.Top4-body-ltop2{
			top: 1.6rem !important;
		}
		
		.Top4-body-ltop3{
			top: 2.4rem !important;
		}
		.Topbanner6{
			height: 2rem ;
			
		}
		
		.Topbanner3-body{
			width: 95% !important;
		}
		
		.Topbanner3{
			height: 4rem;
		}
	}
	
	.Topbanner {
		width: 100%;
		/* height: 6.98rem; */
		position: relative;
		
		/* background-image: url(../assets/img/bj-1.png);
		background-repeat: no-repeat; */
		z-index: 101;
	}
	
	.fade-enter-active, .fade-leave-active {
	  transition: opacity .5s
	}
	.fade-enter, .fade-leave-active {
	  opacity: 0
	}
	
	.Right {
		position: fixed;
		right: 0;
		top: 2.00rem;
		z-index: 102;
	}
	
	.Right-cell {
		position: fixed;
		right: -1.15rem;
		top: 2.00rem;
		z-index: 103;
		transition: 0.2s all linear;
	}
	
	.Right-cell:hover {
		right: 0;
	}
	
	.Right-cell:hover .Right-cell-text-box {
		opacity: 1;
	}
	
	.Top4-body-ltop1{
		top: 1.20rem;
	}
	
	.Top4-body-ltop2{
		top: 2.35rem;
	}
	
	.Top4-body-ltop3{
		top: 3.50rem;
	}
	
	.Right-cell-text {
		padding: 0.10rem 0.10rem 0.10rem 0.05rem;
		border-radius: 0.20rem 0 0 0.20rem;
		margin-bottom: 0.10rem;
		background-color: #188cf3;
		display: flex;
		align-items: center;
		position: relative;
	}
	
	.Right-cell-text-box {
		width: 1.00rem;
		position: absolute;
		bottom: -1.45rem;
		background-color: #fff;
		font-size: 0.14rem;
		text-align: center;
		transition: 0.2s all linear;
		opacity: 0;
		z-index: 100;
	}
	
	.Right-cell-text-img {
		width: 1.00rem;
		position: relative;
		z-index: 99;
	}
	
	.Right-cell1 {
		position: fixed;
		right: -1.0rem;
		top: 2.00rem;
		z-index: 104;
		padding: 0.10rem 0.10rem 0.10rem 0.05rem;
		border-radius: 0.20rem 0 0 0.20rem;
		margin-bottom: 0.10rem;
		background-color: #188cf3;
		display: flex;
		align-items: center;
		margin-top: 0.50rem;
		transition: 0.2s all linear;
	}
	
	.Right-cell1:hover {
		right: 0;
	
	}
	
	.Right-cell2 {
		position: fixed;
		right: -1.10rem;
		top: 2.00rem;
		z-index: 104;
		padding: 0.10rem 0.10rem 0.10rem 0.05rem;
		border-radius: 0.20rem 0 0 0.20rem;
		margin-bottom: 0.10rem;
		background-color: #188cf3;
		display: flex;
		align-items: center;
		margin-top: 0.50rem;
		transition: 0.2s all linear;
	}
	
	.Right-cell2:hover {
		right: 0;
	}
	
	.Right-cell-img {
		width: 0.18rem;
		/* height: 18px; */
		margin-left: 0.10rem;
		font-size: 0.13rem;
		/* padding-right: 20px; */
		color: #fff;
	}
	
	.Right-cell-span {
		font-size: 0.13rem;
		color: #fff;
		margin-left: 0.10rem;
	}
	
	.spandis {}
	
	.Left {
		position: fixed;
		left: -0.08rem;
		top: 3.00rem;
		color: #fff;
		display: flex;
		align-items: center;
		padding: 0.10rem;
		font-size: 0.13rem;
		/* background-color: #188cf3; */
		background-image: url(../assets/img/zxzx.png);
		background-repeat: no-repeat;
		border-radius: 0.05rem;
		z-index: 104;
		/* padding-left: 10px; */
	}
	
	.Left-img {
		width: 0.18rem;
		height: 0.18rem;
		margin-left: 0.10rem;
	
	}
	
	.Topbanner-body {
		width: 90%;
		height: 100%;
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding-top: 0.20rem;
		/* background-color: #060628; */
		z-index: 103;
		text-align: left;
		display: flex;
		/* justify-content: center; */
		align-items: center;
		/* height: ; */
	}
	
	.b-top-imgbox {
		width: 50%;
		padding-bottom: 0.20rem;
	}
	
	.Topbanner-b-top {
		width: 100%;
		display: flex;
		align-items: center;
	}
	
	.b-top-img {
		width: 0.80rem;
	}
	
	.b-top-cell {
		width: 40%;
		display: flex;
		color: #fff;
		align-items: center;
		font-size: 0.14rem;
	}
	
	.t-cell-c {
		flex: 1;
		/* height: 60px; */
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 0.20rem;
		position: relative;
	}
	
	.t-cell-c:before {
		content: "";
		position: absolute;
		top: 0;
		left: 100%;
		/*下划线从右侧开始显示*/
		width: 0;
		height: 100%;
		border-bottom: 2px solid #239ef7;
		transition: 0.2s all linear;
		/*动画效果*/
	}
	
	.t-cell-c:hover:before {
		left: 0;
		/*鼠标滑过时，下划线从右向左移动*/
		width: 100%;
		/*同时，下划线宽度从0-100%*/
	}
	
	.t-cell-c:hover~.t-cell-c:before {
		/*~ 选择器：查找指定元素后面的所有兄弟结点*/
		left: 0;
		/*处于hover后面元素的下划线从左侧开始显示*/
	}
	
	.t-cell-but {
		width: 1.20rem;
		height: 0.40rem;
		background-color: #188cf3;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0.20rem;
		margin-bottom: 0.20rem;
	}
	
	.but-r {
		width: 0.20rem;
		height: 0.20rem;
		margin-right: 0.10rem;
	}
	
	img {
		/* position: absolute; */
		/* width: 100%; */
		vertical-align: top;
	}
	
	.TopB-body {
		/* position: absolute; */
		width: 60%;
		height: 3.00rem;
		left: 0;
		right: 0;
		margin: 0 auto;
		/* top: -50%; */
		/* margin-top: 1.20rem; */
		/* font-family: 'kaiti'; */
		/* background-color: #188CF3; */
		color: #fff;
		line-height: 0.45rem;
	}
	
	.TopB-body-title {
		font-size: 0.25rem;
	}
	
	.TopB-body-lab {
		font-size: 0.20rem;
	}
	
	.sqsy {
		width: 1.60rem;
		height: 0.40rem;
		border-radius: 6px;
		/* margin: 0 auto; */
		margin-top: 0.20rem;
		background-image: url(../assets/img/sqsy.png);
		background-repeat: no-repeat;
		overflow: hidden;
		font-size: 0.2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		/* line-height: 60px; */
		position: relative;
		z-index: 103;
	}
	
	.Topbanner2 {
		width: 100%;
		position: relative;
		text-align: center;
		line-height: 0.45rem;
		z-index: 2;
		/* display: flex;
		flex-direction: column;
		align-items: center; */
		/* justify-content: center; */
	}
	
	.Topbanner2-body {
		width: 100%;
		position: absolute;
		height: 1.80rem;
		/* line-height: 1.80rem; */
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #00082c;
	}
	
	.Topbanner2-body-title {
		font-size: 0.25rem;
		color: #fff;
		margin-top: 0.3rem;
	}
	
	.Topbanner2-body-lab {
		font-size: 0.20rem;
		color: #fff;
	}
	
	.Topbanner2-body-but {
		width: 340px;
		color: #46ffff;
		border: 1px solid #188CF3;
		margin: 0 auto;
		margin-top: 20px;
	}
	
	.Topbanner3 {
		width: 100%;
		/* position: relative; */
		display: flex;
		align-items: center;
		justify-content: center;
		/* z-index: 103; */
	}
	
	.Topbanner3-body {
		width: 10.00rem;
		position: absolute;
		justify-content: space-between;
		/* left: 0; */
		/* right: 0; */
		/* top: 70px; */
		/* margin: 0 auto; */
		display: flex;
		flex-wrap: wrap;
		z-index: 103;
		
	}
	
	.Topbanner3-body-cell {
		/* width: 350px; */
		/* height: 1.00rem; */
		background-color: #fff;
		
		display: flex;
		align-items: center;
		padding: 0.2rem 0.10rem;
		margin-top: 0.10rem;
		margin-right: 0.10rem;
		position: relative;
		overflow: hidden;
	}
	
	.Topbanner3-body-cell:before {
		transition: .3s ease-in-out;
		-webkit-transition: .3s ease-in-out;
		display: block;
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 100%;
		background: #2f87fa;
		background: linear-gradient(90deg, #2f87fa, #05daee);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2f87fa", endColorstr="#05daee", GradientType=1)
	}
	
	.Topbanner3-body-cell:hover:before,
	.Topbanner3-body-cell:hover:before {
		top: 0;
	}
	
	.Topbanner3-body-cell:hover .Topbanner3-body-cell-img{
		transform: scale(.9);
		-webkit-transform: scale(.9)
	}
	
	.Topbanner3-body-cell:hover .cell-le-tit{
		color: #fff !important;
	}
	
	.Topbanner3-body-cell:hover .cell-le-lab{
		color: #fff !important;
	}
	
	.Topbanner3-body-cell-le {
		width: 2.00rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		line-height: 0.2rem;
		z-index: 3;
	}
	
	.Topbanner3-body-cell-img {
		width: 1.00rem;
		height: 0.60rem;
		z-index: 3;
		transition: .3s ease-in-out;
		-webkit-transition: .3s ease-in-out;
	}
	
	.cell-le-tit {
		color: #333;
		font-size: 0.20rem;
		font-weight: bold;
		transition: .3s ease-in-out;
		-webkit-transition: .3s ease-in-out;
	}
	
	.cell-le-lab {
		color: #ccc;
		font-size: 0.12rem;
		transition: .3s ease-in-out;
		-webkit-transition: .3s ease-in-out;
	}
	
	.Topbanner4 {
		width: 100%;
		/* height: 8.00rem; */
		background-color: #f5f5f5;
		position: relative;
		z-index: 102;
		
	}
	
	.Topbanner4-title {
		width: 100%;
		height: 1.50rem;
		text-align: center;
		font-size: 0.30rem;
		line-height: 1.50rem;
		color: #333;
	}
	
	.Topbanner4-body {
		width: 12rem;
		margin: 0 auto;
		display: flex;
		position: relative;
	}
	
	.Topbanner4-body-l {
		width: 3.0rem;
		float: left;
		height: 4.5rem;
	}
	
	.Topbanner4-body-r {
		float: right;
		margin-left: 10px;
		position: absolute;
		right: 0;
		z-index: 2;
		width: 8.90rem;
		height: 4.50rem;
	}
	
	.Top4-body-l {
		position: absolute;
	}
	
	.hover1box {
		position: relative;
	}
	
	.body-l-cell {
		width: 3.0rem;
		height: 1.0rem;
		position: absolute;
		left: 0;
		z-index: 10;
		/* opacity: 1; */
		transition: 0.2s all linear;
	}
	
	.body-l-cell-y {
		width: 3.3rem;
		height: 1.0rem;
		position: absolute;
		left: 0;
		z-index: 10;
		/* opacity: 0; */
		transition: 0.2s all linear;
	}
	
	.xls {
		width: 8.90rem;
		height: 4.50rem;
	}
	
	.Topbanner5 {
		width: 100%;
		z-index: 2;
		/* height: 800px; */
	}
	
	.Topbanner5-title {
		width: 100%;
		height: 1.10rem;
		text-align: center;
		font-size: 0.30rem;
		line-height: 1.50rem;
		color: #333;
	}
	
	.Topbanner5-body {
		width: 16rem;
		margin: 0 auto;
		position: relative;
		z-index: 2;
		/* height: 500px; */
	}
	
	.Topbanner6 {
		width: 100%;
		position: relative;
		z-index: 2;
		
	}
	
	.Topbanner6-body {
		position: absolute;
		left: 0;
		right: 0;
		/* width: 60%; */
		margin: 0 auto;
		
		/* padding: 0.4rem 0; */
	}
	
	.Topbanner6-body-phone {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 0.25rem;
		color: #fff;
		padding: 0.20rem 0;
		/* margin-top: 40px; */
	}
	
	.Top6-phone {
		width: 0.32rem;
		height: 0.32rem;
	}
	
	.Top6-phone-span {
		margin-left: 0.20rem;
	}
	
	.Topbanner6-body-input {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		/* margin-bottom: 0.36rem; */
	}
	
	.Top6-input {
		width: 5.0rem;
		height: 0.60rem;
		line-height: 0.60rem;
		font-size: 0.20rem;
		padding-left: 0.20rem;
		color: #fff;
		border: 1px solid #fff;
	}
	
	.Top6-but {
		width: 1.60rem;
		height: 0.62rem;
		line-height: 0.62rem;
		background-color: #fff;
		margin-left: 0.20rem;
		text-align: center;
		color: #188cf3;
		font-size: 0.25rem;
	
	}
	
	.el-message{
		min-width: 3.8rem !important;
		top: 0.2rem !important;
	}
</style>
