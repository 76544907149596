import axios from "axios";

import {
	Notification,
	MessageBox,
	Message
} from 'element-ui';

const service = axios.create({

	baseURL: 'http://supply-platform-api.lihansy.com.cn',
	timeout: 10000
})

service.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

// 添加请求拦截器
service.interceptors.request.use(function(config) {
	// 在发送请求之前做些什么
	// 是否有token 有则添加
	// if (localStorage.getItem('token')) {
	// 	config.headers.token = localStorage.getItem('token');
	// }
	return config;
}, function(error) {
	// 对请求错误做些什么
	return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function(response) {
	// 对响应数据做点什么
	// 未设置状态码则默认成功状态
	const code = response.data.code || 1;
	
	if (code === 500) {
		Message({
			message: response.data.msg || 'Error',
			type: 'error',
		});
		return Promise.reject(new Error(response.data.msg || 'Error'))
	} else if (code !== 1) { 
		// console.log(response , '111')
		Notification.error({
			title: response.data.msg,
		});
		return Promise.reject('error');
	}
}, function(error) {
	// 对响应错误做点什么
	return Promise.reject(error);
});

export default service
